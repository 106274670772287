import React from "react";
import "./HomePage.css";
import { FormattedMessage } from "react-intl";
import Header from "../_components/Header";
import Button from "../_components/Button";
import HeaderMobile from "../_components/HeaderMobile";
import Footer from "../_components/Footer";
import Slider from "../_components/Slider";
import { Link } from "react-router-dom";
import Arrow from "./rightArrow.svg";
import Image1 from "./image1.jpg";
import Image2 from "./image2.jpg";
import Sponsors from "../_components/Sponsors";
import Media from "react-media";
import Countdown from "../_components/Countdown";

class HomePage extends React.Component {
  render() {
    return (
      <div>
        <div className="Home">
          <Media query={{ minWidth: 768 }}>
            {(matches) =>
              matches ? (
                <div className="header-homepage">
                  <Header />
                </div>
              ) : (
                <HeaderMobile />
              )
            }
          </Media>
          <Slider />
          <section className="Home-countdown">
            <Media query={{ minWidth: 768 }}>
              {(matches) =>
                matches ? "" : <Countdown date="2025-04-04T00:00:00" />
              }
            </Media>
          </section>
          <section className="Home-alert">
            <h2 className="slider-caption2">Apertura de inscripciones el 1 de diciembre de 2024</h2>
          </section>
          <section className="Home-pager">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 col-lg-6">
                  <a
                    className="Home-pager-link"
                    //href="https://eventos.emesports.es/inscripcion/costa-atlantica-mtb-tour-2023/participantes/"
                    href=""
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h2 className="Home-pager-title">Inscritos</h2>
                    <h5 className="Home-pager-subtitle">
                      Consulta la lista de inscritos
                    </h5>
                    <span className="Home-pager-arrow-wrapper">
                      <img
                        className="Home-pager-arrow"
                        src={Arrow}
                        alt="arrow"
                      />
                    </span>
                  </a>
                </div>
                <div className="col-md-12 col-lg-6">
                  <a
                    className="Home-pager-link"
                    href="https://sportmaniacs.com/c/costa-atlantica-mtb-2024"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h2 className="Home-pager-title">
                      Resultados de la prueba
                    </h2>
                    <h5 className="Home-pager-subtitle">
                      Comprueba tu posición de esta edición
                    </h5>
                    <span className="Home-pager-arrow-wrapper">
                      <img
                        className="Home-pager-arrow"
                        src={Arrow}
                        alt="arrow"
                      />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </section>
          <Sponsors />
          <div className="container">
            <div className="row">
              <div className="col-12">
                <section className="description">
                  <h4 className="description-title">
                    Costa Atlántica MTB Tour
                  </h4>
                  <h6 className="description-text text-justify">
                    La <strong>Costa Atlántica MTB Tour</strong>, es una
                    competición por etapas de bicicleta de montaña. los
                    participantes disfrutarán de 3 etapas: una crono nocturna y
                    dos etapas maratón. La prueba tiene un{" "}
                    <strong> formato CHALLENGE</strong>, lo que quiere decir que
                    cada etapa se disputa de forma independiente, por lo que
                    aunque un participante/pareja no finalice alguna de las
                    etapas, podrán disputar el resto. Si un participante/pareja,
                    no finaliza una etapa,{" "}
                    <strong>
                      <u>
                        en la general se computará en dicha etapa, el tiempo del
                        último participante/pareja.
                      </u>
                    </strong>
                    <br />
                    <br />
                    Los participantes se enfrentarán tanto de forma individual como por parejas{" "}
                    en cualquiera de las dos modalidades. La prueba comienza el viernes 4 de abril de{" "}
                    2025 a las 20:00 con una CRONO NOCTURNA por el casco viejo de Pontevedra.{" "}
                    La primera etapa se desarrolla el sábado 5 de abril, con salida a las 9:00 desde la localidad de Pontevedra.{" "}
                    La segunda y última etapa tendrá lugar el domingo 6 de abril (9:00) e igual que las dos anteriores tendrá la salida{" "}
                    y la llegada en Pontevedra.
                    <br />
                    <br />
                    La competición se disputa según el reglamento técnico y
                    deportivo de la Real Federación Española de Ciclismo (RFEC).
                  </h6>
                </section>
              </div>
            </div>
          </div>
          <section className="video">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-8 offset-md-2">
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/n64nR0Hc-QA?si=fNOm2GeArAg_bMqi"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
          <Media query={{ minWidth: 768 }}>
            {(matches) =>
              matches ? (
                ""
              ) : (
                <section className="inscription-fixed-bar">
                  <Button
                    className="inscription-fixed"
                    href="https://sportmaniacs.com/es/services/inscription/costa-atlantica-mtb-2025-22958"
                  >
                    Inscríbete
                  </Button>
                </section>
              )
            }
          </Media>
        </div>
      </div>
    );
  }
}

export default HomePage;
