import React from 'react';
import Header from '../_components/Header';
import Button from '../_components/Button';
import Footer from '../_components/Footer';
import ImageHero from './image1.jpg';
import Image2 from './image2.jpg';
import pdfEs from './reglamento.pdf';
import './RulesPage.css';
import { FormattedMessage } from 'react-intl';
import Media from "react-media";
import HeaderMobile from '../_components/HeaderMobile';

class RulesPage extends React.Component {
  render() {
    return (
      <div className="Rules">
        <Media query={{ minWidth: 768 }}>
          {matches =>
            matches ? (
              <Header />
            ) : (
              <HeaderMobile />
            )}
        </Media>
        <section className="Rules-hero" 
          style={{
            height: '150px',
            width: '100%',
          }}>
        </section>
        <section className="Rules-info">
          <div className="container-fluid">
          <div className="row">
        <div className="col-12">
          <section className="reglamento text-center">
            <h4 className="reglamento-title">Reglamento</h4>
            <p>
              La COSTA ATLÁNTICA MTB TOUR, es una competición por etapas de
              bicicleta de montaña, dividida en tres etapas maratón en la que
              los participantes se enfrentarán tanto de forma individual como
              por parejas.
            </p>
            <p>
              La competición se disputa según el reglamento técnico y deportivo
              de la Real Federación Española de Ciclismo (RFEC).
            </p>
            <p>Puedes descargar el reglamento completo desde este enlace</p>
             <a
              className="btn btn-primary"
              href={pdfEs}
              target="_blank"
              rel="noopener noreferrer"
            >
              Reglamento
            </a>
          </section>
        </div>
      </div>
          </div>
        </section>
        <Footer />
         <Media query={{ minWidth: 768 }}>
          {matches =>
            matches ? (
              ''
            ) : (
              <section className="inscription-fixed-bar">
                <Button className="inscription-fixed" href="https://sportmaniacs.com/es/services/inscription/costa-atlantica-mtb-2025-22958" target="_blank" rel="noopener noreferrer">
                  Inscríbete
                </Button>
              </section>
            )}
        </Media>
      </div>
    );
  }
}

export default RulesPage;
