import React from 'react';
import Header from '../_components/Header';
import Button from '../_components/Button';
import Footer from '../_components/Footer';
import ImageHero from './image1.jpg';
import Image2 from './image2.jpg';
import pdfEs from './seguro.pdf';
import './SeguroPage.css';
import { FormattedMessage } from 'react-intl';
import Media from 'react-media';
import HeaderMobile from '../_components/HeaderMobile';

class SeguroPage extends React.Component {
  render() {
    return (
      <div className='Seguro'>
        <Media query={{ minWidth: 768 }}>
          {(matches) => (matches ? <Header /> : <HeaderMobile />)}
        </Media>
        <section
          className='Seguro-hero'
          style={{
            height: '150px',
            width: '100%',
          }}></section>
        <section className='Seguro-info mb-5'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12'>
              <h4 className="tarifas-title">Seguro de devolución</h4>
                <article className='Seguro-info-article'>
                  <p className='Seguro-info-text text-justify'>
                    Todo participante inscrito para poder obtener la devolución
                    de su inscripción deberá marcar en la ficha de inscripción
                    la opción <strong>“SEGURO DE DEVOLUCIÓN”</strong>. Aquellos
                    participantes que{' '}
                    <strong>
                      {' '}
                      no se acojan a este seguro, no tendrán derecho a la
                      devolución
                    </strong>{' '}
                    de su inscripción en ninguna de las circunstancias.
                  </p>
                  <p className='Seguro-info-text text-justify'>
                    El <u>seguro de devolución es opcional</u> y tiene un coste
                    de <strong>10€ (prueba completa) y 5€ (solo 1 día)</strong>a mayores de la inscripción (según
                    el tramo de inscripción elegido).
                  </p>
                  <p className='Seguro-info-text text-justify'>
                    Los participantes que se acojan a este seguro podrán
                    solicitar la <strong>DEVOLUCIÓN ÍNTEGRA</strong> de su
                    inscripción (no se incluye el coste de la ropa adquirida)
                    hasta el día{' '}
                    <u>miércoles 2 de abril de 2025  a las 23:59 horas.</u>
                  </p>
                  <p className='Seguro-info-text text-justify'>
                    Para más información véase el documento adjunto.{' '}
                  </p>
                  <div className='Seguro-button-wrapper'>
                    <a
                      className='Seguro-pdf-button'
                      href={pdfEs}
                      target='_blank'
                      rel='noopener noreferrer'>
                      Seguro de devolución
                    </a>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <Media query={{ minWidth: 768 }}>
          {matches =>
            matches ? (
              ''
            ) : (
              <section className="inscription-fixed-bar">
                <Button className="inscription-fixed" href="https://sportmaniacs.com/es/services/inscription/costa-atlantica-mtb-2025-22958" target="_blank" rel="noopener noreferrer">
                  Inscríbete
                </Button>
              </section>
            )}
        </Media>
      </div>
    );
  }
}

export default SeguroPage;
