import React from 'react';
import Header from '../_components/Header';
import Button from '../_components/Button';
import Footer from '../_components/Footer';
import ImageHero from './image1.jpg';
import etapa1 from './etapa1.jpg';
import etapa2 from './etapa2.jpg';
import etapa3 from './etapa3.jpg';
import 'react-html5video/dist/styles.css';
import './EtapasPage.css';
import { FormattedMessage } from 'react-intl';
import Media from 'react-media';
import HeaderMobile from '../_components/HeaderMobile';

class EtapasPage extends React.Component {
  render() {
    return (
      <div className="Embassador">
        <Media query={{ minWidth: 768 }}>
          {(matches) => (matches ? <Header /> : <HeaderMobile />)}
        </Media>
        <section
          className="Embassador-hero"
          style={{
            height: '150px',
            width: '100%',
          }}
        />
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <section className="etapas">
                <h4 className="etapas-title">Etapas</h4>
                <div className="row no-gutters">
                  <div className="col-12 col-sm-7">
                    <img
                      className="img-fluid img-etapa1"
                      src={etapa1}
                      alt="image"
                    />
                  </div>
                  <div className="col-12 col-sm-5">
                    <div className="etapa-info">
                      <h5>Etapa 1: Crono nocturna</h5>
                      <div className="etapa-datos">
                        <p>3km aprox.</p>
                        {/* <p>1300m acumulado</p> */}
                      </div>
                      <p className="etapa-info-text">
                        Se disputará en Pontevedra el viernes 4 de abril a
                        las 20:00 en un recorrido urbano por su zona vieja, con
                        una distancia aproximada de 4km. Será un recorrido
                        Trepidante, divertido y que pondrá el corazón a las
                        máximas pulsaciones. <br />
                        Los participantes saldrán de 4 en 4 con una distancia de
                        1 minuto entre cada salida. El circuito se abrirá a las
                        19: 00 horas para que los participantes puedan
                        reconocerlo. <br />
                        En esta crono empezará la batalla. Los tiempos contarán para la general de la prueba.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-12 col-sm-5 order-2 order-md-1">
                    <div className="etapa-info">
                      <h5>Etapa 2: Comienza la aventura</h5>
                      <div className="etapa-datos">
                        <p>70/80km</p>
                        {/* <p>1700m acumulado</p> */}
                      </div>
                      <p className="etapa-info-text">
                        Será una etapa de 70/80 km de distancia que saldrá y
                        llegará al corazón de Pontevedra. Una etapa en la que
                        los bikers podrán disfrutar de todo tipo de terreno,
                        subidas de infarto, senderos infinitos pegados a la
                        costa, bajadas rápidas que se entrelazan con otras
                        ratoneras, y una bajada final que dejará a todo el mundo
                        con una sonrisa. <br />
                        Esta etapa es la seña de identidad de la prueba y en sus
                        cerca de 80 km permitirá a todos los corredores saborear
                        la costa muy de cerca, casi tanto que el agua puede
                        bañar sus ruedas. <br />
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-sm-7 order-1 order-md-2">
                    <img
                      className="img-fluid img-etapa2"
                      src={etapa2}
                      alt="image"
                    />
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-12 col-sm-7">
                    <img
                      className="img-fluid img-etapa3"
                      src={etapa3}
                      alt="image"
                    />
                  </div>
                  <div className="col-12 col-sm-5">
                    <div className="etapa-info">
                      <h5>Etapa 3: El fin de la épica</h5>
                      <div className="etapa-datos">
                        <p>65km</p>
                      </div>
                      <p className="etapa-info-text">
                      Será una etapa de 65km de distancia con salida y llegada en Pontevedra.{" "}
                        Las panoramicas espectaculares de toda la costa será una de las señas de identidad{" "}
                        de esta etapa, en la que seguiremos disfrutando de un terreno variado y divertido.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <Footer />
        <Media query={{ minWidth: 768 }}>
          {(matches) =>
            matches ? (
              ''
            ) : (
              <section className="inscription-fixed-bar">
                <Button
                  className="inscription-fixed"
                  href="https://sportmaniacs.com/es/services/inscription/costa-atlantica-mtb-2025-22958"
                  target="_blank"
                  rel="noopener noreferrer">
                  Inscríbete
                </Button>
              </section>
            )
          }
        </Media>
      </div>
    );
  }
}

export default EtapasPage;
