import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Close from "./x.svg";
import LanguageSwitcher from "../LanguageSwitcher";

class Menu extends Component {
  render() {
    var visibility = "hide";

    if (this.props.menuVisibility) {
      visibility = "show";
    }

    return (
      <div id="flyoutMenu" className={visibility}>
        <div className="Header-top">{/* <LanguageSwitcher /> */}</div>
        <button className="btn" onMouseDown={this.props.handleMouseDown}>
          <img src={Close} alt="icon" />
        </button>
        <ul>
          <h3>
            <a
              href="#collapseLaPrueba"
              role="button"
              className="Header-link"
              data-toggle="collapse"
              aria-expanded="false"
              aria-controls="collapseLaPrueba"
            >
              La Prueba
            </a>
          </h3>
          <div className="collapse menu-mobile-collapse" id="collapseLaPrueba">
            <p>
              <Link className="Header-link" to="/etapas">
                Etapas
              </Link>
            </p>
            <p>
              <Link className="Header-link" to="/categorias">
                Categorías
              </Link>
            </p>
            <p>
              <Link className="Header-link" to="/tarifas">
                Tarifas
              </Link>
            </p>
            <p>
              <Link className="Header-link" to="/seguro-devolucion">
                Seguro de devolución
              </Link>
            </p>
            <p>
              <Link className="Header-link" to="/reglamento">
                Reglamento
              </Link>
            </p>
            <p>
              <Link className="Header-link" to="/preguntas-frecuentes">
                Preguntas frecuentes
              </Link>
            </p>
          </div>
          <h3>
            <a
              href="#collapseGlobalDXTRaces"
              role="button"
              className="Header-link"
              data-toggle="collapse"
              aria-expanded="false"
              aria-controls="collapseGlobalDXTRaces"
            >
              <FormattedMessage
                id="header.races.globaldxt"
                defaultMessage="Pruebas GlobalDXT"
              />
            </a>
          </h3>
          <div
            className="collapse menu-mobile-collapse"
            id="collapseGlobalDXTRaces"
          >
            <a
              className="dropdown-item"
              href="http://www.laroadpontevedra.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              La Road Pontevedra
            </a>
            <a
              className="dropdown-item"
              href="http://www.epicracepontevedra.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Epic Race Pontevedra
            </a>
            <a
              className="dropdown-item"
              href="http://www.pontevedra4picos.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Pontevedra 4 picos
            </a>
          </div>
          <h3>
            <a
              href="#collapseFriendRaces"
              role="button"
              className="Header-link"
              data-toggle="collapse"
              aria-expanded="false"
              aria-controls="collapseFriendRaces"
            >
              <FormattedMessage
                id="header.races.friends"
                defaultMessage="Pruebas Amigas"
              />
            </a>
          </h3>
          <div
            className="collapse menu-mobile-collapse"
            id="collapseFriendRaces"
          >
            <a
              className="dropdown-item"
              href="https://mountainquest.pt/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Mountain Quest
            </a>
            <a
              className="dropdown-item"
              href="https://dourobikerace.com/es/dbr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Douro Bike Race
            </a>
            <a
              className="Header-link"
              href="https://www.gigantedepiedra.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Gigante de Piedra
            </a>
            <a
              className="Header-link"
              href="https://laleyendadetartessos.com/?gclid=Cj0KCQjwj5mpBhDJARIsAOVjBdpsPRMWA-5B5G-60b_h1lTRd9SXXZQAfr3fQStRnpdrHPlrYZrW5CEaAgV6EALw_wcB"
              target="_blank"
              rel="noopener noreferrer"
            >
              La Leyenda de Tartesos
            </a>
            <a
              className="Header-link"
              href="https://www.amigosdamontanha.com/_maratona-btt-5-cumes"
              target="_blank"
              rel="noopener noreferrer"
            >
              Maratona 5 cumes
            </a>
          </div>
          <h3>
            <a
              href="#multimedia"
              role="button"
              className="Header-link"
              data-toggle="collapse"
              aria-expanded="false"
              aria-controls="multimedia"
            >
              Multimedia
            </a>
          </h3>
          <div className="collapse menu-mobile-collapse" id="multimedia">
            <p>
              <Link className="Header-link" to="/fotos">
                Fotos
              </Link>
            </p>
            <p>
              <Link className="Header-link" to="/videos">
                Vídeos
              </Link>
            </p>
          </div>
          <h3>
            <Link className="Header-link" to="/alojamiento">
              Alojamiento
            </Link>
          </h3>
          <h3>
            <Link className="Header-link" to="/sponsors">
              Sponsors
            </Link>
          </h3>
        </ul>
        <div className="Menu-mobile-button-wrapper">
          <a
            className="Menu-mobile-button"
            href="https://sportmaniacs.com/es/services/inscription/costa-atlantica-mtb-2025-22958"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="home.enroll" defaultMessage="Inscríbete" />
          </a>
        </div>
      </div>
    );
  }
}

export default Menu;
