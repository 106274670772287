import React from 'react';
import Header from '../_components/Header';
import Button from '../_components/Button';
import Footer from '../_components/Footer';
import ImageHero from './image1.jpg';
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import './PaquetesPage.css';
import { FormattedMessage } from 'react-intl';
import Media from "react-media";
import HeaderMobile from '../_components/HeaderMobile';

class PaquetesPage extends React.Component {
  render() {
    return (
      <div className="Media">
        <Media query={{ minWidth: 768 }}>
          {matches =>
            matches ? (
              <Header />
            ) : (
              <HeaderMobile />
            )}
        </Media>
        <section className="Media-hero"
          style={{
            height: '150px',
            width: '100%',
          }}>
        </section>
        <div className="container-fluid">
        <div className="row">
      <div className="col-12">
        <h4 className="paquetes-title">Paquetes</h4>
        <p className="paquetes-subtitle text-center">
          Además de la inscripción, la organización ofrece varios paquetes que
          pueden ayudar a tener una mejor experiencia de la prueba.
        </p>
      </div>
    </div>
    {/* <div className="row mb-5">
      <div className="col-12 col-md-4 paquete-type p-5">
        <h5>Servicio de mecánica final de etapa</h5>
        <h6>29€</h6>
      </div>
      <div className="col-12 col-md-8 paquete-description p-5">
        <p>
          Permite que los participantes dejen sus bicicletas en el set de
          mecánica para ser revisada, engrasada, lavada y depositada en el
          guardabicicletas. En este precio solo está incluida la mano de obra y
          no las piezas que sea preciso reponer. Los participantes deben recoger
          su bicicleta antes de las 20:00 en el guardabicicletas habilitado por
          la organización. El servicio de mecánica se realizará al finalizar la
          primera y segunda etapa. Al finalizar la tercera etapa tan solo
          recibirán el servicio de lavado.{" "}
        </p>
      </div>
    </div> 
    <div className="row mb-5">
      <div className="col-12 col-md-4 paquete-type p-5">
        <h5>Servicio última etapa</h5>
        <h6>40€</h6>
      </div>
      <div className="col-12 col-md-8 paquete-description p-5">
        <p>
          Aquellos participantes que quieran disputar únicamente la última etapa
          podrán hacerlo. El coste de este servicio es de 40 euros, ofreciendo a
          dichos participantes solo la opción de participar, con todos los
          servicios incluidos en esa etapa ( no podrán acceder al maillot de
          evento). Estos participantes solo podrán acceder a la categoría
          individual y deberán salir desde el último cajón. No podrán entrar en
          la clasificación general, ni recibirán medalla de fisnisher.{" "}
          <strong>
            El límite para este tipo de servicio es de 250 participantes.
          </strong>
        </p>
      </div>
    </div>*/}
    <div className="row mb-5">
      <div className="col-12 col-md-8 paquete-description p-5 order-2 order-md-1">
        <p>
          Al final de la 2ª y 3ª etapa y por riguroso orden de llegada al set de
          masaje, las personas que elijan este servicio recibirán un masaje de
          20 minutos que les permitirá afrontar la siguiente etapa con éxito.
        </p>
      </div>
      <div className="col-12 col-md-4 paquete-type p-5 order-1 order-md-2">
        <h5>Servicio de masaje</h5>
        <h6>29€</h6>
      </div>
    </div>
        </div>
        <Footer />
         <Media query={{ minWidth: 768 }}>
          {matches =>
            matches ? (
              ''
            ) : (
              <section className="inscription-fixed-bar">
                <Button className="inscription-fixed" href="https://sportmaniacs.com/es/services/inscription/costa-atlantica-mtb-2025-22958" target="_blank" rel="noopener noreferrer">
                  Inscríbete
                </Button>
              </section>
            )}
        </Media>
      </div>
    );
  }
}

export default PaquetesPage;
