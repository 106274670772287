import React, { useState } from "react";
import Header from "../_components/Header";
import Footer from "../_components/Footer";
import ImageHero from "./image1.jpg";
import "./PhotosPage.css";
import { FormattedMessage } from "react-intl";
import Media from "react-media";
import HeaderMobile from "../_components/HeaderMobile";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import * as Tabs from "@radix-ui/react-tabs";
import Button from "../_components/Button";

import images2019 from "./2019/images";
import images2022 from "./2022/images";
import images2023 from "./2023/images";
import images2024 from "./2024/images";

const PhotosPage = () => {
  const [index, setIndex] = useState(-1);

  return (
    <div className="Media">
        <Media query={{ minWidth: 768 }}>
          {(matches) => (matches ? <Header /> : <HeaderMobile />)}
        </Media>
        <section
          className="Media-hero"
          style={{
            height: "150px",
            width: "100%",
          }}
        ></section>
        <div className="row">
          <div className="col-12">
            <h4 className="categorias-title">Fotos</h4>
          </div>
        </div>
      <section className="m-5">
        <Tabs.Root defaultValue="2024" orientation="vertical">
          <Tabs.List className="tab-header-list" aria-label="tabs photos">
            <Tabs.Trigger className="tab-header-item" value="2019">2019</Tabs.Trigger>
            <Tabs.Trigger className="tab-header-item" value="2022">2022</Tabs.Trigger>
            <Tabs.Trigger className="tab-header-item" value="2023">2023</Tabs.Trigger>
            <Tabs.Trigger className="tab-header-item" value="2024">2024</Tabs.Trigger>
          </Tabs.List>
          <Tabs.Content value="2019">
            <PhotoAlbum
              layout="rows"
              photos={images2019}
              targetRowHeight={150}
              onClick={(event, photo, index) => setIndex(index)}
              componentsProps={{ imageProps: { loading: "lazy" } }}
            />
            <Lightbox
              open={index >= 0}
              index={index}
              close={() => setIndex(-1)}
              slides={images2019}
            />
          </Tabs.Content>
          <Tabs.Content value="2022">
            <PhotoAlbum
              layout="rows"
              photos={images2022}
              targetRowHeight={150}
              onClick={(event, photo, index) => setIndex(index)}
              componentsProps={{ imageProps: { loading: "lazy" } }}
            />
            <Lightbox
              open={index >= 0}
              index={index}
              close={() => setIndex(-1)}
              slides={images2022}
            />
          </Tabs.Content>
        <Tabs.Content value="2023">
            <PhotoAlbum
              layout="rows"
              photos={images2023}
              targetRowHeight={150}
              onClick={(event, photo, index) => setIndex(index)}
              componentsProps={{ imageProps: { loading: "lazy" } }}
            />
            <Lightbox
              open={index >= 0}
              index={index}
              close={() => setIndex(-1)}
              slides={images2023}
            />
          </Tabs.Content>
          <Tabs.Content value="2024">
            <PhotoAlbum
              layout="rows"
              photos={images2024}
              targetRowHeight={150}
              onClick={(event, photo, index) => setIndex(index)}
              componentsProps={{ imageProps: { loading: "lazy" } }}
            />
            <Lightbox
              open={index >= 0}
              index={index}
              close={() => setIndex(-1)}
              slides={images2024}
            />
          </Tabs.Content>
        </Tabs.Root>
      </section>
      <Footer />
      <Media query={{ minWidth: 768 }}>
          {matches =>
            matches ? (
              ""
            ) : (
              <section className="inscription-fixed-bar">
                <Button
                  className="inscription-fixed"
                  href="https://sportmaniacs.com/es/services/inscription/costa-atlantica-mtb-2025-22958"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Inscríbete
                </Button>
              </section>
            )
          }
        </Media>
    </div>
  );
};

export default PhotosPage;
